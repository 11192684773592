import browserUpdate from 'browser-update/update.npm';

// See : https://github.com/browser-update/browser-update/wiki/Details-on-configuration
browserUpdate({
  required: {
    e: -2, // Edge
    i: 12, // IE
    f: -2, // Firefox
    s: -2, // Safari
    c: -2, // Chrome
  },
  insecure: true,
});
